import React from 'react'
import Layout from '../../components/layout'
import { JumbleSentenceCreator } from '../../components/ChallengeCreators'

const JumbleSentenceCreatorPage = ({ challengeId }) => {
  return <Layout>
    <JumbleSentenceCreator challengeId={challengeId} />
  </Layout>
}

export default JumbleSentenceCreatorPage
